import React from 'react'
import Contactform from '../components/ContactForm'

const About = () => {
  return (
    <div className="page">
      <div className="container">
        <div className="content">
          <div className="form-title">
            <h1>Get In Touch</h1>
          </div>
          <Contactform />
        </div>
      </div>
    </div>
  )
}

export default About
