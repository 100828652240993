import React, { Component } from 'react'

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.ContactForm = React.createRef()
    this.state = {
      name: '',
      email: '',
      message: ''
    }
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  update = (name, e) => {
    console.log(name, e.target.value)
    this.setState({ [name]: e.target.value })
  }

  render() {
    const handleSubmit = event => {
      event.preventDefault()
      // const form = this.ContactForm.current
      console.log(this.state)
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: this.encode({
          'form-name': 'contact',
          ...this.state
        })
      })
        .then(() => window.location.replace('/success'))
        .catch(error => window.alert(error))

      this.setState({
        name: '',
        email: '',
        message: ''
      })
    }
    return (
      <div className="form">
        <form name="contact" onSubmit={handleSubmit} netlify="true">
          <label htmlFor="name">
            <span>
              Name <span className="required">*</span>
            </span>
            <input
              type="text"
              className="input-field"
              name="name"
              value={this.name}
              placeholder="Your Name"
              onChange={e => this.update('name', e)}
            />
          </label>

          <label htmlFor="email">
            <span>
              Email <span className="required">*</span>
            </span>
            <input
              type="email"
              className="input-field"
              name="email"
              value={this.email}
              placeholder="Your Email"
              onChange={e => this.update('email', e)}
            />
          </label>

          <label htmlFor="message">
            <span>
              Message <span className="required">*</span>
            </span>
            <input
              type="textarea"
              className="input-field"
              name="message"
              value={this.message}
              placeholder="Your Message"
              onChange={e => this.update('message', e)}
            />
          </label>

          <button className='submit' type="submit">Send</button>
        </form>
      </div>
    )
  }
}
